@charset "UTF-8";

#navAccountSignedOut #loginRegisterMobile {
  display: none;
}

@media screen and (max-width: 1316px) {
  #navAccountSignedOut #loginRegisterBtn button {
    width: 80px;
  }
}

@media screen and (max-width: 804px) {
  #navAccountSignedOut #loginRegisterMobile {
    display: block;
  }

  #navAccountSignedOut #loginRegisterBtn {
    display: none;
  }
}