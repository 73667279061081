@charset "UTF-8";

#error {
  text-align: center;
  min-height: 80vh;
}

#error img {
  height: 70vh;
}

@media screen and (max-width: 520px) {
  #error img {
    height: auto;
    max-width: 80vw;
  }
}