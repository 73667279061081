@charset "UTF-8";

#createListingSuccess {
  min-height: 75vh;
  text-align: center;
  padding-top: 10%;
}

#createListingSuccess .successBtn {
  margin-left: 20px;
}