@charset "UTF-8";

#listingOwnerMenuComponent {
  cursor: default;
}

#listingOwnerMenuComponent ul {
  padding: 0;
  margin: 0;
}

#listingOwnerMenuComponent ul li {
  list-style: none;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  border: 1px solid black;
  width: 80%;
  margin: 0 auto;
  background-color: #6d6a6a;
  color: #fff;
  margin-top: 6px;
}

#listingOwnerMenuComponent ul li:hover {
  background-color: #c2bfbf;
  color: black;
  cursor: pointer;
}