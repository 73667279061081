@charset "UTF-8";

#formComponent {
  background-color: #f7f5f5;
  width: 100%;
  height: 100%;
}

#formComponent h2 {
  margin: 0;
  text-align: center;
}

#questionForm {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 500px;
  text-align: center;
}

#questionForm textarea {
  border: 1px solid black;
  border-radius: 0.5rem;
  width: 400px;
  height: 125px;
  resize: none;
}

#questionForm input {
  width: 200px;
  height: 25px;
  border: 1px solid black;
  border-radius: 0.5rem;
  margin-bottom: 5px;
}

#questionForm button {
  width: 200px;
  height: 30px;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: #c2bfbf;
  margin-top: 10px;
}

#questionForm button:hover {
  cursor: pointer;
}