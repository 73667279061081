@charset "UTF-8";

#searchResult {
  width: 80vw;
  max-width: 1100px;
  min-width: 372px;
  min-height: 80vh;
  margin: 0 auto;
}

#searchResult h1, #searchResult h2 {
  padding: 0;
  margin: 0;
  text-align: center;
}

#searchResult #searchContainer {
  background-color: #cccccc;
  margin-top: 20px;
  height: 100px;
}

#searchContainer {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 605px) {
  #searchResult {
    width: 100vw;
  }
}