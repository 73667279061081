@charset "UTF-8";

#cookieInfo {
  width: 900px;
  height: 85vh;
  background-color: #f7f5f5;
  margin: 40px auto 0;
}

#cookieInfo h1 {
  text-align: center;
}

#cookieInfo p {
  padding: 20px;
  font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
  #cookieInfo {
    width: 100%;
    min-width: 340px;
  }
}