@charset "UTF-8";

#searchStart {
  background-image: url("../img/startPageImg.jpg"); /* Free to use: https://pixabay.com/sv/photos/e-handel-f%C3%B6rs%C3%A4ljning-p%C3%A5-n%C3%A4tet-2140604/ */
  background-size: 100% 100%;
  height: 550px;
  width: 90%;
  margin-left: 5%;
  margin-top: 1rem;
  border-radius: 1rem;
}

#startSearchForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.startSearchInput {
  height: 35px;
  opacity: 0.93;
  border-radius: 0.5rem;
  font-size: 1rem;
  border: 1px solid black;
}

.startSearchInput:focus {
  border-width: 2px;
  outline: none;
}

#startSearchForm #searchQuery {
  width: 400px;
}

#startSearchForm #searchButton {
  width: 70px;
  text-align: center;
  margin-left: 5px;
  font-weight: bold;
  font-size: 1rem;
  background-color: #ebe6e6;
}

@media screen and (max-width: 804px) {
  #searchStart {
    height: 350px;
  }
}

@media screen and (max-width: 450px) {
  #searchStart {
    background-color: #139CC0;
    background-image: none;
    height: 200px;
  }
}

