@charset "UTF-8";

.registerFormRow {
  width: 400px;
  margin: 0 auto 0;
}

.registerFormColumn {
  width: 200px;
  float: left;
}

.registerFormColumn label {
  font-size: 1rem;
  font-weight: bold;
}

#registerFormHasAccount {
  font-size: 1rem;
  font-weight: bold;
  margin: 5px 0 0 0;
}

.registerFormBtn {
  width: 200px;
  height: 30px;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: #ebe6e6;
  margin-top: 0.5rem;
}

.registerFormBtn:active, .registerFormBtn:focus {
  border: 2px solid black;
}

.registerFormBtn:hover {
  cursor: pointer;
  background-color: #cccccc;
}

.registerFormColumn input {
  width: 180px;
  height: 20px;
  border: 1px solid black;
  border-radius: 0.25rem;
}

#acceptMessage {
  display: block;
  margin-top: 170px;
}

@media screen and (max-width: 405px) {
  .registerFormRow {
    width: 300px;
    margin-left: 70px;
  }

  .registerFormBtn {
    display: block;
    margin: 0 auto;
  }

  .registerFormColumn input:last-child {
    margin-bottom: 10px;
  }
}