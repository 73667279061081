@charset "UTF-8";

#navMobile {
  display: none;
}

#navBar {
  position: fixed;
  min-height: 75px;
  max-height: fit-content;
  left: 0;
  right: 0;
  background-color: #ebe6e6;
  z-index: 1;
}

#navFlexContainer {
  float: left;
}

.navbarElements {
  width: 50px;
  height:75px;
}

#navLogo {
  min-width: 20vw;
  float: left;
}

#globNav {
  width: 600px;
  justify-content: center;
  float: left;
}

#globNav ul {
  display: block;
  float: left;
  width: 600px;
  height: 100%;
  margin-top: 0;
  margin: 0;
}

#globNav li {
  display: block;
  float: left;
  height: 100%;
  width: 6rem;
  list-style: none;
  margin-left: 1rem;
  line-height: 75px;
  text-align: center;
  background-color: #ebe6e6;
}

#globNav li p {
    width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.link {
  color: black;
  text-decoration: none;
}

#globNav li p:hover {
  background-color: #cccccc;
  cursor: pointer;
}

#login {
min-width: 450px;
float: left;
margin-left: 200px;
}

#login #navSignedIn {
  margin: 0;
  padding: 0;
  margin-left: 100px;
}

#login #navSignedIn #dropDownMenu {
  margin-right: 0;
}

#login button {
  height: 50px;
  width: 150px;
  margin-left: 2rem;
  margin-top: 0.75rem;
  border-radius: 0.5rem;
}

#login button:hover {
  cursor: pointer;
}

#navMargin {
  display: block;
  width: 100vw;
  max-width: 100%;
  height: 75px;
  z-index: 0;
}

@media screen and (max-width: 1686px) {
  #login {
    margin-left: 110px;
  }
}

@media screen and (max-width: 1572px) {
  #login {
    margin-left: 0;
  }
  #globNav {
    width: 580px;
  }
}

@media screen and (max-width: 1314px) {
  #login {
    min-width: 270px;
  }
}

@media screen and (max-width: 1202px) {
  #globNav {
    width: 500px;
  }
}

@media screen and (max-width: 1004px) {
  #navBar {
    height: 150px;
  }

  #navLogo {
    float: none;
    margin: 0 auto;
  }

  #login {
    margin-left: 50px;
  }

  #navMargin {
    height: 150px;
  }
}

@media screen and (max-width: 860px) {
  #login {
    margin-left: 0;
  }
}

@media screen and (max-width: 804px) {
  #navBar {
    height: 75px;
  }

  #navMargin {
    height: 75px;
  }

  #navFlexContainer, #navLogo {
    display: none;
  }

  /* css nedanför är delvis baserad på w3schools mobila navbar: https://www.w3schools.com/howto/howto_js_mobile_navbar.asp */
  #navMobile {
    display: block;
    background-color: #ebe6e6;
    position: relative;
    height: 100%;
    min-width: 340px;
  }

  #navMobile #btns {
    background-color: #139CC0;
  }

  #navMobile #btns .link {
    display: block;
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    margin: 0;
  }

  #navMobile #btns a.icon {
    background: black;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  #navMobile #btns .link:hover {
    background-color: #ddd;
    color: black;
    cursor: pointer;
  }

  #navMobile #toggleMenu {
    float: right;
    width: 100px;
    height: 50px;
    margin-top: 11px;
    margin-right: 30px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    background-color: #139CC0;
    border-radius: 0.5rem;
  }

  #navMobile #toggleMenu:hover {
    cursor: pointer;
    background-color: #53c0db;
  }
}
