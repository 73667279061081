@charset "UTF-8";

#ListingPageDelete {
  text-align: center;
  width: 80vw;
  min-width: 340px;
  min-height: 80vh;
  margin: 0 auto;
}

#ListingPageDelete h1 {
  margin-top: 5rem;
}

#ListingPageDelete button {
  text-align: center;
  margin: 1rem 1rem 0 1rem ;
  width: 100px;
  height: 40px;
  background-color: #ebe6e6;
  border: 1px solid black;
  border-radius: 0.25rem;
}

#ListingPageDelete button:hover {
  cursor: pointer;
  background-color: #cccccc;
}

