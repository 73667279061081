@charset "UTF-8";

#navSignedInMobile {
  display: none;
}

#dropdownMenu {
  display: block;
  float: right;
  width: fit-content;
  height: 75px;
  line-height: 75px;
  font-weight: bold;
  font-size: 1.5rem;
}

#dropdownMenuContent {
  display: none;
  position: absolute;
  background-color: #ebe6e6;
  width: 200px;
  padding: 0;
  margin: 0;
  z-index: 9999;
}

#dropdownMenu:hover, #dropdownMenu:hover #dropdownMenuContent {
  display: block;
  cursor: pointer;
}

#dropdownMenu #arrow {
  color: #139CC0;
}

#dropdownMenuContent ul {
  padding: 0;
}

#dropdownMenuContent li {
  list-style: none;
  padding-left: 10px;
}

#dropdownMenuContent li:hover {
  background-color: #cccccc;
  cursor: pointer;
}

#signedInNavDropDownLink {
  text-decoration: none;
  color: black
}

@media screen and (max-width: 1444px) {
  #dropdownMenu {
    float: left;
    margin-left: 50px;
  }
}

@media screen and (max-width: 1076px) {
  #dropdownMenu {
    margin-left: 0;
  }
}

@media screen and (max-width: 804px) {
  #navSignedInMobile {
    display: block;
  }

  #dropdownMenu {
    display: none;
  }
}