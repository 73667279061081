@charset "UTF-8";

.questionContainer {
  border-bottom: 1px solid black;
}

.questionTitle {
  background-color: #ebe6e6;
  height: 50px;
  cursor: pointer;
}

.questionAnswer {
  display: none;
  min-height: 50px;
  padding-left: 10px;
}

.questionAnswer p {
  margin: 0;
}

.questionContainer h3 {
  margin: 0;
  line-height: 50px;
  padding-left: 10px;
  overflow-x: auto;
}