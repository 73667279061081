@charset "UTF-8";

#gdprInfo {
  width: 900px;
  min-height: 85vh;
  background-color: #f7f5f5;
  margin: 40px auto 0;
}

#gdprInfo h1 {
  text-align: center;
}

#gdprInfo p, #gdprInfo h2 {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 900px) {
  #gdprInfo {
    width: 100%;
    min-width: 340px;
  }
}