@charset "UTF-8";

#queuePage {
  min-height: 80vh;
  width: 80vw;
  min-width: 340px;
  margin: 0 auto;
  padding: 50px;
}

#queuePage h1, #queuePage h2  {
  text-align: center;
  margin: 0;
}

#queuePage h2 {
  margin-bottom: 1rem;
}

#queuePage table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 70vw;
  overflow-x: auto;
}

#queuePage td, #queuePage th {
  border: 1px solid #000000;
  padding: 8px;
  text-align: center;
}

#queuePage tr:nth-child(even) {
  background-color: #ebe6e6;
}

#queuePage #backBtn:hover {
  cursor: pointer;
  background-color: #cccccc;
}

#queuePage #remove {
  background-color: #c51919;
  text-align: center;
}

#queuePage #remove:hover {
  cursor: pointer;
  background-color: #f14e4e
}

@media screen and (max-width: 642px) {
  #queuePage {
    padding: 0;
  }
}

@media screen and (max-width: 594px) {
  #queuePage {
    margin: 0;
  }
}