@charset "UTF-8";

#listingFormContainer h1 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#listingFormContainer {
  background-color: #fff;
  width: 80vw;
  max-width: 900px;
  min-width: 340px;
  min-height: 800px;
  margin: 0 auto;
}

#listingForm input, #listingForm label, #listingForm button, #listingForm Select {
  display: block;
  margin: 0 auto 20px;
  text-align: center;
}

#listingForm Select {
  margin-bottom: 10px;
}

#listingForm #productImage {
  color: transparent;
  width: 70px;
}

#listingForm #imageSizeWarning {
  color: #ff0000;
}

#listingForm textarea {
  display: block;
  width: 80%;
  height: 300px;
  margin: 0 auto;
  resize: none;
}

#listingForm #description-info {
  float: right;
  padding-right: 100px;
}

#listingFormFileWrapper {
  text-align: center;
  margin-bottom: 10px;
}

#listingFormFileWrapper div {
  display: inline-block;
  vertical-align: top;
  width: 160px;
  height: 75px;
  margin-left: 1px;
}

#listingFormFileWrapper div input {
  width: 160px;
}

#listingFormFileWrapper div img {
  height: 75px;
}

@media screen and (max-width: 700px) {
  #listingForm textarea {
    height: 200px;
  }
}