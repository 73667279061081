@charset "UTF-8";

#latest {
  padding: 1rem;
  min-height: 750px; 
  background-color: #ebe6e6;
  border-radius: 1rem;
  overflow-y: auto;
}

#latest h2 {
  margin: 0;
  text-align: center;
}

#latestFlexContainer {
  display: flex;
  flex-direction: column;
}

#latestFlexContainer div {
  background-color: #cccccc;
  border-radius: 0.5rem;
  margin-top: 10px;
  cursor: pointer;
  min-height: 125px;
}

#latestFlexContainer div div {
  float: left;
  margin-left: 25px;
  margin-top: 2px;
  height: 120px;
  width: calc(100% - 110px - 30px);
}

#latestListing:hover {
  transform: scale(1.05);
  transition-duration: 150ms;
}

#latestFlexContainer div h3 {
  text-align: center;
  padding-left: 25px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#latestFlexContainer div p {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 10px 0 0 0;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


#latestFlexContainer div img {
  display: block;
  width: 100px;
  height: 100px;
  margin-top: 12px;
  margin-bottom: 5px;
  margin-left: 10px;
  float: left;
  border-radius: 0.5rem;
}

#latestFlexContainer div button {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}

#latestFlexContainer div span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}