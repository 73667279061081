@charset "UTF-8";

#searchComponent {
  width: 100%;
  height: 100%;
}

#searchComponent .radioBtn {
  transform: scale(1.4)
}

#searchComponent label {
  font-size: 1.2rem;
  font-weight: bold;
}