@charset "UTF-8";

#listingTypePageCategories {
  background-color: #ebe6e6;
  width: 90%;
  min-height: 1160px;
  margin: 0 auto;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

#listingTypePageCategories h2 {
  margin-top: 2rem;
}

#listingTypePageCategories h2, #listingTypePageCategories h3 {
  text-align: center;
}

#listingTypePageCategories ul {
  margin: 0;
  padding: 0;
}

#listingTypePageCategories ul li {
  list-style: none;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #6d6a6a;
  color: #fff;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
}

#listingTypePageCategories ul li:hover {
  cursor: pointer;
  color: #000;
  background-color: #cccccc;
}