@charset "UTF-8";


#myAccountNav {
  width: 100vw;
  height: 50px;
  background-color: #c2bfbf;
}

#myAccountNav ul {
  display: block;
  height: 100%;
  width: 100%;
  margin-top: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}

#myAccountNav ul li {
  display: inline-block;
  height: 100%;
  width: 150px;
  list-style: none;
  margin-left: 1rem;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.125rem;
  background-color: #6d6a6a;
}

#myAccountNav ul li:hover {
  cursor: pointer;
  background-color: #a7a4a4;
}

#myAccountContentContainer {
  width: 80vw;
  min-height: 130vh;
  background-color: #f7f5f5;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 605px) {
  #myAccountContentContainer {
    width: 100vw;
  }
}

@media screen and (max-width: 622px) {
  #myAccountContentContainer {
    width: 100vw;
    min-width: 340px;
  }

  #myAccountNav ul li {
    display: block;
    width: 100%;
  }

  #myAccountNav {
    height: 150px;
    width: 100%;
  }

  #myAccountNav ul {
    margin: 0;
    padding: 0;
  }

  #myAccountNav ul li {
    height: 50px;
    margin: 0;
    padding: 0;
    border-top: 1px solid #fff;
  }
}