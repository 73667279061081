@charset "UTF-8";

#footer {
  width: 100%;
  height: 300px;
  background-color: #ebe6e6;

}

#footer #sectionContainer {
  width: fit-content;
  margin: 0 auto;
  height: 100%;
}

#footer #sectionContainer .section {
  height: 100%;
  width: 300px;
  float: left;
  padding-left: 100px;
}

#footer #sectionContainer .section:nth-child(even) {
  border-left: 3px solid #6d6a6a;
}

#footer #sectionContainer .section h1 {
  font-size: 1.3rem;
  text-align: center;
}

#footer #sectionContainer .section ul {
  list-style: none;
  padding: 0px;
}

#footer #sectionContainer .section ul li {
  margin-top: 15px;
  height: 30px;
  width: fit-content;
  padding: 5px;
}

#footer #sectionContainer .section ul li .footerLink {
  color: black;
  text-decoration: none;
  font-weight: bold;
  line-height: 30px;
}

#footer #sectionContainer .section ul li:hover {
  cursor: pointer;
  background-color: #cccccc;
}

@media screen and (max-width: 900px) {
  #footer {
  height: 500px;

}
  #footer #sectionContainer .section {
  float: none;
  height: 38%;
  padding-left: 0px;
}
#footer #sectionContainer .section:nth-child(even) {
  border-left: none;
}
}