@charset "UTF-8";

#listingPageOwnerMenu {
  display: block;
  height: 60px;
  text-align: center;
  margin: 0;
  padding: 0;
}

#listingPageOwnerMenu li {
  display: inline-block;
  height: 100%;
  width: 7rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 60px;
  background-color: #6d6a6a;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

#listingPageOwnerMenu li:hover {
  cursor: pointer;
  background-color: #a7a4a4;
}

@media screen and (max-width: 1640px) {
  #listingPageOwnerMenu li {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #fff;
  }
}

@media screen and (max-width: 1271px) {
  #listingPageOwnerMenu li {
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #fff;
    border-left: none;
  }

  #listingPageOwnerMenu {
    height: 110px;
  }
}