@charset "UTF-8";

#AccountProfileContainer {
  display: block;
  width: 400px;
  min-height: 400px;
  margin: 0px auto;
  text-align: center;
}

#AccountProfileContainer h1 {
  text-align: center;
}