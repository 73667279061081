@charset "UTF-8";

#helpElement {
  display: grid;
  grid-template-areas: 
  'title title title'
  'questions questions form';
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 75vh;
  max-width: 1250px;
  margin: 0 auto;
}

#helpTitle {
  grid-area: title; 
  display:table;
  background-color: #fff;
  height: 300px;
}

#text {
  display: table-cell;
  text-align:center;
  vertical-align: middle;
  font-size: 2rem;
}

#helpQuestions {
  grid-area: questions;
  background-color: #fff;
  min-height: fit-content;
  min-width: 500px;
}

#helpForm {
  grid-area: form;
  height: 400px;
}

@media screen and (max-width: 1000px) {
  #helpElement {
  grid-template-areas: 
  'title title title'
  'questions questions questions'
  'form form form';
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 75vh;
  }

  #helpForm {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
