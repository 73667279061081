@charset "UTF-8";

.loginBtn {
  width: 200px;
  height: 30px;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: #ebe6e6;
}

.loginBtn:active, .loginBtn:focus {
  border: 2px solid black;
}

.loginBtn:hover {
  cursor: pointer;
  background-color: #cccccc;
}

#loginForm button {
    margin-top: 15px;
}

/* Input */

#loginForm label, #loginFormNoAccountText {
  font-size: 1rem;
  font-weight: bold;
}

#loginForm input {
  width: 200px;
  height: 25px;
  border: 1px solid black;
  border-radius: 0.25rem;
}