@charset "UTF-8";

#listing {
  display: grid;
  height: 120px;
  width: 100%;
  margin-top: 1rem;
  grid-template-columns: 5% 20% 55% 10% 68px;
  grid-template-areas: 
  ". image title price ownerMenu";
  background-color: #ebe6e6;
  cursor: pointer;
}

#listing:hover {
  background-color: #cfcaca;
}

.listingOwnerMenu {
  grid-area: ownerMenu;
}

#listingImage {
  grid-area: image;
  margin: 0;
}

#listingImage img {
  width: 90%;
  margin-top: 5px;
}

#listingTitle {
  grid-area: title;
  display: block;
  white-space: nowrap;
}

#listingTitle h2 {
  text-align: center;
  line-height: 120px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#listingPrice {
  display: block;
  position: relative;
  grid-area: price;
}

#listingPrice {
  display: block;
  white-space: nowrap;
}

#listingPrice p {
  position: absolute;
  width: 130px;
  bottom: 1.5rem;
  right: 0;
  margin: 0;
  color: black;
  font-weight: bold;
  font-size: 1.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 778px) {
  #listing {
    grid-template-columns: 5% 20% 48% 10% 68px;
  }
}

@media screen and (max-width: 430px) {
  #listing {
    grid-template-columns: 5% 20% 45% 10% 68px;
  }
}