@charset "UTF-8";

#termsInfo {
  width: 900px;
  height: 85vh;
  background-color: #f7f5f5;
  margin: 40px auto 0;
}

#termsInfo h1 {
  text-align: center;
}

#termsInfo ol li {
  margin-top: 10px;
  font-size: 1.2rem;
}

#termsInfo p {
  padding: 20px;
  font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
  #termsInfo {
    width: 100%;
    min-width: 340px;
  }
}
