@charset "UTF-8";

#start {
  margin-top: 30px;
  display: grid;
  background-color: #fff;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    "mainLeft mainLeft rightMenu"
    "mainLeft mainLeft rightMenu";
}

h1 {
  margin: 0;
}

#mainLeft {
  grid-area: mainLeft;
  height: 600px;
  width: 100%;
}

#rightMenu {
  grid-area: rightMenu;
  height: 100%;
  width: 100%;
}

#latest {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 85%;
}

@media screen and (max-width: 804px) {
  #mainLeft {
    height: 400px;
  }
}

@media screen and (max-width: 1312px) {
  #start {
    grid-template-columns: 400px 400px calc(100vw - 800px);
  }
}

@media screen and (max-width: 1148px) {
  #start {
    grid-template-columns: 100vw;
    grid-template-areas:
      "mainLeft"
      "rightMenu";
  }

  #start #startShortcuts {
    display: none;
  }

  #rightMenu {
    grid-area: rightMenu;
    width: 100vw;
  }


  #rightMenu #latest {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 450px) {
  #searchStart {
    background-color: #139CC0;
    background-image: none;
    height: 200px;
    min-width: 2;
    min-width: 380px;
  }
  #mainLeft {
    height: 250px;
  }

  #rightMenu #latest {
    min-width: 360px;
  }
}