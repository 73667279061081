@charset "UTF-8";

#startShortcuts {
  margin-top: 1rem;
  width: 90%;
  margin-left: 5%;
  height: 100px;
}

#startShortcuts button {
  display: inline-block;
  height: 80px;
  width:  160px;
  margin: 0 auto;
  border-radius: 0.5rem;
  border: 1px solid #000;
  font-weight: bold;
  font-size: 2rem;
  background-color: #139CC0;
}

#startShortcuts button:last-child {
  margin-left: 15rem;
}

#startShortcuts #btnContainer {
  margin: 0 auto;
  width: fit-content;
  margin-top: 55px;
}

#startShortcuts #btnContainer button:hover {
  cursor: pointer;
  background-color: #4ac4e2;
}