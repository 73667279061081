@charset "UTF-8";

#listings {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

#listings h2 {
  text-align: center;
}