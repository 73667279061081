@charset "UTF-8";

#myListings {
  width: 80%;
  margin: 0 auto;
}

#myListings h1 {
  text-align: center;
}

@media screen and (max-width: 925px) {
  #myListings {
    width: 100%;
  }
}