@charset "UTF-8";

#paginationMenu h4 {
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
}

#paginationMenu #paginationMenuBtnContainer {
  width: fit-content;
  margin: 0 auto;
}

#paginationMenu #paginationMenuBtnContainer button {
  height: 25px;
  width: fit-content;
  margin-top: 1rem;
  margin-left: 0.25rem;
  font-weight: bold;
}

#paginationMenu #paginationMenuBtnContainer button:first-child {
  margin-left: 0;
}

#paginationMenu #paginationMenuBtnContainer button:hover {
  cursor: pointer;
}