@charset "UTF-8";

#userQueuedListings {
  width: 80%;
  margin: 0 auto;
}

#userQueuedListings h1, #userQueuedListings p {
  text-align: center;
}

@media screen and (max-width: 925px) {
  #userQueuedListings {
    width: 100%;
  }
}