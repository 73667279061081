@charset "UTF-8";

#listingPageGridContainer {
  display: grid;
  grid-template-columns: 0.6fr 0.6fr 2.7fr 0.9fr 200px 0.6fr;
  grid-template-rows: 0.2fr 1.8fr 0.2fr fit-content 0.3fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . owner owner . ."
    ". image image image image ."
    ". price title title date ."
    ". description description ownerInfo ownerInfo ."
    ". . . . . .";
}

#listingPageGridContainer .owner {
  grid-area: owner;
  display: block;
}

#listingPageGridContainer .price {
  grid-area: price;
}

#listingPageGridContainer .price p {
  font-weight: bold;
  padding: 5px;
  font-size: 2rem;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#listingPageGridContainer .image {
  display: block;
  grid-area: image;
  background-color: #f7f5f5;
  border-radius: 1rem 1rem 0 0;
}

#listingPageGridContainer .image img {
  display: block;
  margin: 0 auto;
  height: 500px;
  max-width: 100%;
}

#listingPageGridContainer .description {
  display: block;
  grid-area: description;
  padding: 1rem;
  background-color: #f7f5f5;;
  border-radius: 0 0 0 1rem;
}

#listingPageGridContainer .description p {
  word-break: break-all;
}

#listingPageGridContainer .ownerInfo {
  grid-area: ownerInfo;
  padding: 1rem;
  background-color: #ebe6e6;
  border-radius: 0 0 1rem 0;
}

#listingPageGridContainer .date {
  grid-area: date;
  display: block;
  overflow: hidden;
}

#listingPageGridContainer .date p {
  display: block;
  float: right;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#listingPageGridContainer .title {
  display: block;
  overflow: hidden;
  grid-area: title;

}

#listingPageGridContainer .title h1 {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#listingPageSendMessageBtn {
  display: block;
  background-color: #90ee90;
  border: 1px solid black;
  border-radius: 5px;
  width: 200px;
  height: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 50px auto;
}

#listingPageSendMessageBtn:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition-duration: 150ms;
}

.ownerInfo p, .ownerInfo h2 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 804px) {
  #listingPageGridContainer .title h1 {
    font-size: 1.4rem;
    line-height: 50px;
    margin-left: 30px;
  }

  #listingPageGridContainer .price p, #listingPageGridContainer .date p {
    font-size: 1.4rem;
    line-height: 50px;
  }

  #listingPageGridContainer {
    grid-template-columns: 0.6fr 0.6fr 2.7fr 0.9fr 200px 0.6fr;
    grid-template-rows: 50px 200px 50px 550px 2.5fr 0.3fr;
    gap: 0px 0px;
    grid-template-areas:
      ". . owner owner . ."
      "image image image image image image"
      ". price title title date ."
      "description description description description description description"
      "ownerInfo ownerInfo ownerInfo ownerInfo ownerInfo ownerInfo"
      ". . . . . .";
  }
  .ownerInfo {
    text-align: center;
    height: 550px;
  }

  #listingPageGridContainer .description {
    border-radius: 0;
  }

  #listingPageGridContainer .image {
    border-radius: 0;
  }

  #listingPageGridContainer .ownerInfo {
  border-radius: 0;
}

  #listingPageGridContainer .image img {
    height: 200px;
  }
}