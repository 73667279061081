@charset "UTF-8";

#cookiePopup {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: fit-content;
  background-color: #6d6a6a;
  color: #fff;
  font-weight: bold;
  line-height: 40px;
  padding-left: 5px;
  z-index: 999999999;
}

#cookiePopup p {
  width: fit-content;
}

#cookiePopup button {
  margin-left: 15px;
}