@charset "UTF-8";

#sellMain {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 30px 100px calc(100% - 100px);
  grid-template-columns: 17% 54% 28.5% ;
  grid-template-areas:
    "title title title"
    "categories search latest"
    "categories listings latest";
  min-height: 80vh;
}

#titleContainer {
  grid-area: title;
  text-align: center;
  margin-top: 5px;
  min-width: 370px;
}

#sellCategories {
  grid-area: categories;
}

#sellSearch {
  grid-area: search;
  background-color: #ebe6e6;
  margin-top: 2rem;
  border-radius: 1rem;
  border-left: 1px solid black;
  min-width: 370px;
}

#sellListings {
  grid-area: listings;
  min-width: 370px;
}

#sellLatest {
  grid-area: latest;
  padding: 1rem;
  min-width: 370px;
}

@media screen and (max-width: 1120px) {
  #sellMain {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 30px 100px 1201px 850px;
    grid-template-columns: 17% 54% 28.5% ;
    grid-template-areas:
      "title title title"
      "categories search search"
      "categories listings listings"
      "latest latest latest";
  }

  #sellMain #sellLatest {
    margin: 0 auto;
    margin-left: 15px;
  }

  #sellMain #sellLatest #latest {
    width: 90vw;
    min-width: 370px;
  }
}

@media screen and (max-width: 814px) {
  #sellMain {
    grid-template-columns: 20vw 50vw 30vw;
  }
  #sellMain #sellLatest {
    margin-left: 20px;
  }
  #sellMain #sellLatest #latest {
    width: 85vw;
  }
}

@media screen and (max-width: 708px) {
  #sellMain {
    grid-template-columns: 30vw 40vw 30vw;
  }
}

@media screen and (max-width: 534px) {
  #sellMain #sellLatest {
    margin-left: 0;
  }
  #sellMain {
    grid-template-columns: 30vw 40vw 30vw;
    grid-template-rows: 30px 100px 1201px 850px;
    grid-template-areas:
      "title title title"
      "search search search"
      "categories listings listings"
      "latest latest latest";
  }
}

@media screen and (max-width: 456px) {
  #sellMain {
    grid-template-rows: 30px 100px 1201px 1200px;
    grid-template-areas:
      "title title title"
      "search search search"
      "listings listings listings"
      "categories categories categories"
      "latest latest latest";
  }
}