@charset "UTF-8";

/* Container */

#AccountWindowContainer {
  position: fixed;
  display: block;
  width: 550px;
  height: 450px;
  top: 75px;
  left: 35%;
  z-index: 9999;
  background-color: #424242;
  color: white;
  text-align: center;
  border-radius: 1rem;
  box-shadow: #05050575 0px 20px 40px, #08080848 0px 15px 10px;
}

#AccountWindowContainer h2 {
  margin: 0 auto 10px;
}

/* Buttons */

#AccountWindowContainerCloseBtn {
  float: right;
  height: 40px;
  width: 40px;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: red;
  border: none;
  outline: none;
  border-radius: 0 1rem 0 1rem;
}

#AccountWindowContainerCloseBtn:focus, #AccountWindowContainerCloseBtn:active {
  border: 2px solid black;
}

#AccountWindowContainerCloseBtn:hover {
  cursor: pointer;
}

/* Modal */

#AccountWindowContainerModalBox {
  height: 25px;
  margin-top: 20px;
}

@media screen and (max-width: 1686px) {
  #AccountWindowContainer {
    left: 25%;
  }
}

@media screen and (max-width: 1004px) {
  #AccountWindowContainer {
    top: 150px;
    left: 20%;
  }
}

@media screen and (max-width: 804px) {
  #AccountWindowContainer {
    top: 75px;
    left: 15%;
  }
}

@media screen and (max-width: 704px) {
  #AccountWindowContainer {
    left: 10%;
  }
}

@media screen and (max-width: 640px) {
  #AccountWindowContainer {
    left: 5%;
  }
}

@media screen and (max-width: 595px) {
  #AccountWindowContainer {
    left: 0;
  }
}

@media screen and (max-width: 550px) {
  #AccountWindowContainer {
    width: 100vw;
  }
}

@media screen and (max-width: 405px) {
  #AccountWindowContainer {
    width: 100vw;
    min-width: 340px;
    height: fit-content;
    padding-bottom: 20px;
  }
}